import * as React from "react";
import { graphql, Link } from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Layout from "../../components/layout";
import Activation from "../../components/activation";
import { getImage } from "gatsby-plugin-image";
import LightBlueButton from "../../styles/styled-component/LightBlueButton";
import "./index.module.css";
import { container, card, row, header, paragraph, body, footer, footerRow, button } from "./index.module.css";
import { GatsbyImage } from "gatsby-plugin-image";
import { SEO } from "../../components/seo";

const CaseStudiesPage = ({ data }) => {
  return (
    <Layout pageTitle="Case Studies">
      <Container fluid className={container}>
        <div className={header}>
            <h5 style={{ color: "#0097be" }}>You're in good company</h5>
            <h1>Hundreds of yachts run on Floatist</h1>
            <p>
            Explore how our customers are harnessing the power of our software to transform their operations, enhance guest experiences, and achieve remarkable success.
            </p>
        </div>



        {/* All case studies */}
        <Row className={row}>
          {data.allMdx.nodes.map((node) => (
            <Col sm={6} md={6} lg={3}>
                <Card className={card}>
                    <GatsbyImage
                        image={getImage(
                        node.frontmatter.hero_image.childImageSharp
                        )}
                        style={{
                            borderTopLeftRadius: "20px",
                            borderTopRightRadius: "20px",
                          }}
                    />
                <Card.Body>
                <Card.Text className={body}>
                  <p>
                  {node.frontmatter.abstract}
                  </p>
                </Card.Text>
                <Card.Footer className={footer}>
                  <Row className={footerRow}>
                    <Col>
                      <h5 className={paragraph}>{node.frontmatter.base_name}</h5>
                    </Col>
                    <Col>
                      <AnchorLink to={`/blog/${node.frontmatter.slug}`}>
                        <LightBlueButton className={button}>
                          Read case study{" "}
                          <i className="fa-solid fa-arrow-right"></i>
                        </LightBlueButton>
                      </AnchorLink>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card.Body>
            </Card>

            </Col>
          ))}
        </Row>
      </Container>

      <Activation
        title="The #1 Yacht, Fleet and Charter Management Solution"
        buttonText="Request a demo"
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { category: { eq: "Case study" } } }
    ) {
      nodes {
        frontmatter {
          author
          date(formatString: "MMM DD, Y")
          title
          abstract
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
          category
          slug
          base_name
        }
        id
      }
    }
  }
`;

export default CaseStudiesPage;

export const Head = () => (
  <SEO description="Explore our blog content and learn what you can do to help your yacht charter business" />
);
